<template>
  <ButtonsInstaLink class-name="btn btn-reg btn-primary rounded-2xl btn-hover-shadow-primary" :slug="slug" :new-tab="newTab">
    <span>{{ content }}</span>
    <span v-if="hasArrow">
      <VectorsChevronRight />
    </span>
  </ButtonsInstaLink>
</template>

<script>
export default {
  name: 'PrimaryButton',
  props: {
    slug: { type: String, required: true },
    content: { type: String, required: true },
    newTab: { type: Boolean, default: false },
    hasArrow: { type: Boolean, default: true },
  },
}
</script>
