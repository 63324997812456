<template>
  <ButtonsInstaLink class-name="btn-link btn-sm" :slug="slug" :new-tab="newTab">
    {{ content }}
  </ButtonsInstaLink>
</template>

<script>
export default {
  name: 'LinkButton',
  props: {
    slug: { type: String, required: true },
    content: { type: String, required: true },
    newTab: { type: Boolean, default: false },
  },
}
</script>
