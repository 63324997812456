export const slugify = (str: string) => {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '')
}

export const priceModify = (price: string) => {
  if (Number(price) === Math.round(Number(price))) {
    return Number(price) + '€'
  }
  return parseFloat(price).toFixed(2) + '€'
}

export const splitPrice = (price: string) => {
  const checkRoundPrice = Number(price) === Math.round(Number(price))
  const fixedPrice = parseFloat(price).toFixed(2)
  const integerPart = Math.trunc(Number(fixedPrice))
  const decimalPart = fixedPrice.toString().split('.')[1]

  return {
    whole: checkRoundPrice ? integerPart : parseFloat(price).toFixed(2),
    integer: integerPart,
    decimal: checkRoundPrice ? null : `.${decimalPart}`,
  }
}

export const getTotal = (a: string, b: string) => {
  return (Number(a) + Number(b)).toFixed(2)
}

export const getTotalMultiple = (...values: string[]) => {
  return values.reduce((total, value) => {
    const number = Number(value)
    return isNaN(number) ? total : total + number
  }, 0)
}

export const isInSubscription = (vehicleAvailability: string) => {
  return (
    vehicleAvailability?.includes('Εξαντλήθηκε') ||
    vehicleAvailability?.includes('soldout')
  )
}

export const isOffer = (vehiclePrice: string, vehicleInitialPrice: string) => {
  return (
    Number(vehiclePrice.replace('€', '')) <
    Number(vehicleInitialPrice?.replace('€', ''))
  )
}

export const isValentines = (vehicleTagsValentine: Object) => {
  return Array.isArray(vehicleTagsValentine)
    ? vehicleTagsValentine.includes('secret_valentine')
    : vehicleTagsValentine === 'True'
}

export const isNew = (vehicleAvailability: string) => {
  return (
    vehicleAvailability.includes('Καινούργιο') ||
    vehicleAvailability.includes('new')
  )
}

export const isSoon = (vehicleAvailability: string) => {
  return (
    vehicleAvailability.includes('Σύντομα κοντά σας') ||
    vehicleAvailability.includes('comingsoon')
  )
}

export const isReadyDeliver = (vehicleAvailability: string) => {
  return (
    vehicleAvailability.includes('Eτοιμοπαράδοτο') ||
    vehicleAvailability.includes('readyfordelivery')
  )
}

export const displayOldForm = (vehicleTags: Array<string>) => {
  return (
    vehicleTags &&
    ['display_old_form'].some(t =>
      vehicleTags.includes(t),
    )
  )
}

export const isSuperDeal = (vehicleTags: Array<string>) => {
  return (
    vehicleTags &&
    ['offer', 'super_deal'].every(t => vehicleTags.includes(t))
  )
}

export const tf = (
  key: string,
  fallback: string,
  locale: string,
  values: Array<string>,
): string | undefined => {
  const i18n = useI18n()

  if (i18n.te(key, locale)) {
    return i18n.t(key, locale, values)
  }

  if (fallback !== undefined) {
    return fallback
  }

  return key
}
export const isObjectEmpty = (object: Record<string, string>) => {
  return Object.keys(object).length > 0
}

export const to = (object: Record<string, string>) => {
  const i18n = useI18n()
  const locale: ComputedRef<string> = computed(() => i18n.locale.value)

  return object[locale.value]
}

export const createVehicleUrl = (
  vehicle: {
    properties: {
      brandSlug: string
      modelSlug: string
    }
    sku: {
      brandSlug: string
      modelSlug: string
    }
    skuId: string,
    id: string
  },
  isSellVehicle: boolean,
) => {
  const brand = !isSellVehicle ? vehicle?.properties?.brandSlug : vehicle?.sku?.brandSlug
  const model = !isSellVehicle ? vehicle?.properties?.modelSlug : vehicle?.sku?.modelSlug
  const vehicleId = !isSellVehicle ? vehicle?.skuId : vehicle?.id

  if (isSellVehicle) {
    return `/buy/${brand}/${model}?id=${vehicleId}`
  }

  return `/leasing/${brand}/${model}?skuId=${vehicleId}`
}

export const createEbikeUrl = (vehicle: { properties: { brandSlug: string; modelSlug: string }; skuId: string }) => {
  const brand = vehicle.properties.brandSlug
  const model = vehicle.properties.modelSlug
  const skuId = vehicle.skuId

  return `/instaride/${brand}/${model}?skuId=${skuId}`
}

export const createVehicleTypeUrl = (vehicle: { properties: { brandSlug: string; modelSlug: string }; skuId: string; dataType: string }, url = 'blueground') => {
  const brand = vehicle.properties.brandSlug
  const model = vehicle.properties.modelSlug
  const skuId = vehicle.skuId
  const type = vehicle.dataType

  return `/leasing/${url}/${type}/${brand}/${model}?skuId=${skuId}`
}

export const prettifySlug = (slug: string) => slug.replace('-', ' ')

export const capitalize = (value: string) => value && value[0].toUpperCase() + value.substring(1)

export const truncate = (string: string, value: number) => {
  return string.length > value ? string.substring(0, value) + '…' : string
}

export const clearSpaces = (string: string) => {
  return string.replace(/\s/g, '')
}

export const getImagesByTag = (images: Array<any>, tag: string) => {
  if (!images || images.length === 0) { return null }

  const elImage = images?.find(image => image?.tags?.includes(`${tag}-el`))
  const enImage = images?.find(image => image?.tags?.includes(`${tag}-en`))

  return {
    el: elImage || enImage,
    en: enImage || elImage,
  }
}

export const clearPTags = (string: string) => {
  return string.includes('<p>') || string.includes('</p>') ? string.replace(/<\/?p>/g, '') : string
}

export const fetchVehicles = async (vehicleType: string, vehiclesQueryOptions: { page: Number }) => {
  let i = 0
  let pageVehicles: any
  const allVehicles = []
  do {
    i = i + 1
    vehiclesQueryOptions.page = i
    pageVehicles = await useLedgerApi(`/v1/catalog/${vehicleType}`, vehiclesQueryOptions)
    allVehicles.push(...pageVehicles)
  } while (pageVehicles.length > 0)
  return allVehicles
}

export const getObjectRange = (obj: Object) => {
  return [...Array(Object.keys(obj).length).keys()]
}

export const getFaqStructuredData = (faqs: any) => {
  const { rt } = useI18n({ useScope: 'local' })
  // Removes all HTML tags
  const tagRegex = /<[^>]+>/ig
  // Replaces all <br> tags with newline
  const brRegex = /<br\w*\/?>/ig
  const mainEntity = []
  for (const faq of faqs) {
    mainEntity.push({
      '@type': 'Question',
      name: rt(faq.question).replace(brRegex, '\n').replace(tagRegex, ''),
      acceptedAnswer: {
        '@type': 'Answer',
        text: rt(faq.answer).replace(brRegex, '\n').replace(tagRegex, ''),
      },
    })
  }
  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity,
  }
}

export const sortAlphabetically = (array: Array<any>, prefix: string, shouldTranslate: Boolean) => {
  const i18n = useI18n()
  return array.sort((a, b) => {
    const aValue = shouldTranslate ? i18n.t(`${prefix}.${a.value}`) : a.value
    const bValue = shouldTranslate ? i18n.t(`${prefix}.${b.value}`) : b.value

    return aValue.localeCompare(bValue, i18n.locale, { sensitivity: 'base' })
  })
}

export const findMinimumPrice = (vehicles: Array<any>) => {
  return vehicles.reduce((min, vehicle) => {
    const price = parseFloat(vehicle.price)
    return price < min ? price : min
  }, parseFloat(vehicles[0].price))
}

export const findMaximumPrice = (vehicles: Array<any>) => {
  return vehicles.reduce((max, vehicle) => {
    const price = parseFloat(vehicle.price)
    return price > max ? price : max
  }, parseFloat(vehicles[0].price))
}

export const getYoutubeIdFromUrl = (url: string) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)
  return (match && match[7].length === 11) ? match[7] : false
}

export const getSmallestPrice = (pricing: { price: string; enabled: boolean }[]) => {
  let smallestPriceObject = pricing.filter(item => item.enabled)[0]

  for (let i = 1; i < pricing.length; i++) {
    if (parseFloat(pricing[i].price) < parseFloat(smallestPriceObject.price)) {
      smallestPriceObject = pricing[i]
    }
  }

  return smallestPriceObject
}

export const getOpenPrice = (pricing: Pricing[]) => {
  const result = pricing.filter(item => item.type === 0)
  return result[0]
}

export const formatPrice = (price: string): string => {
  if (!price) { return price }
  const [integerPart, decimalPart] = price.split('.')
  const formattedIntegerPart = new Intl.NumberFormat('de-DE').format(parseInt(integerPart))

  if (decimalPart === '00' || decimalPart === undefined) {
    return formattedIntegerPart
  } else {
    return `${formattedIntegerPart},${decimalPart}`
  }
}

export const formatNumbers = (number: number): string => {
  return new Intl.NumberFormat('de-DE').format(number)
}

export const displayInitialPrice = (tags: Array<any>, campaignTag: string, isCampaign: Boolean, initialPrice: string, initialPriceWithTax: string): boolean => {
  return ((tags.includes('offer') || (tags.includes(campaignTag) && isCampaign)) && initialPriceWithTax !== undefined && initialPrice !== undefined && Number(initialPriceWithTax) !== 0 && Number(initialPrice) !== 0)
}

export const showCampaignLabel = (isCampaign: boolean, campaignBgColor: string, campaignLabelText: string) => { return isCampaign && campaignBgColor && campaignLabelText }

export const displayAvailability = (availability: string) => { return availability !== 'unknownavailability' }

export const isVehicleCampaign = (enableCampaign: boolean, vehicle: object, activeCampaignTag: string) => { return enableCampaign && vehicle.tags.includes(activeCampaignTag) }

export const createRangeArray = (range: Array<any>, increment: number) => {
  const result = []
  for (let i = range[0]; i <= range[1]; i += increment) {
    result.push(i)
  }
  return result
}

export const scrollToContent = (query: string, yOffset = 0) => {
  const content = document.querySelector(query)
  if (content) {
    const y = content.getBoundingClientRect().top + window.scrollY + yOffset
    window.scrollTo({ top: y, behavior: 'smooth' })
  }
}

export const getUrlWithOptions = (props: { src: string, width: number | string, height: number | string, options: object }, extraOptions: {[key: string]: any}) => {
  const config = useRuntimeConfig()
  const baseUrl = ref(config.DOMAIN || 'https://www.instacar.gr')

  if (config.DISABLE_CDN === 'true') {
    return props.src
  }

  if (props.src === '' || props.src === '/' || !props.src) {
    return props.src
  }

  if (extraOptions?.ratio) {
    if (props?.width && !extraOptions?.width) {
      extraOptions.width = extraOptions?.ratio * props?.width
    }
    if (props?.height && !extraOptions?.height) {
      extraOptions.height = extraOptions?.ratio * props?.height
    }
    delete extraOptions?.ratio
  }
  const options = {
    format: 'webp',
    metadata: 'none',
    width: props?.width || undefined,
    height: props?.height || undefined,
    ...props?.options,
    ...extraOptions,
  }
  const optionsText = Object.entries(options).map(([key, value]) => `${key}=${value}`)
  return `${baseUrl.value}/cdn-cgi/image/${optionsText.join(',')}/${encodeURIComponent(props.src)}`
}

export const toggleOverflow = (value: boolean) => {
  if (value) {
    document.getElementsByTagName('html')[0].classList.add('overflow-hidden')
  } else {
    document.getElementsByTagName('html')[0].classList.remove('overflow-hidden')
  }
}

export const applyCampaignsToVehicles = (vehicles: any[], campaigns: any[], campaignsIds: any[]) => {
  const sortedCampaigns = campaigns?.sort((a: any[], b: any[]) => {
    return new Date(b.createdAt) - new Date(a.createdAt)
  })
  const newVehicles = vehicles?.map((item) => {
    let newVehicle = { ...item }
    campaignsIds?.forEach((campaignId) => {
      if (newVehicle?.tags?.includes(campaignId)) {
        const newCampaign = sortedCampaigns?.find(campaign => campaign?.id === campaignId)
        newVehicle = {
          ...newVehicle,
          campaignId,
          campaignImages: newCampaign?.images,
          campaignTexts: newCampaign?.texts,
        }
      }
    })
    return newVehicle
  })
  return newVehicles
}

export const applyCampaignToVehicle = (vehicle: any, campaigns: any[], campaignsIds: any[]) => {
  let newVehicle = { ...vehicle }
  const sortedCampaigns = campaigns?.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt)
  })
  campaignsIds?.forEach((campaignId) => {
    if (newVehicle?.tags?.includes(campaignId)) {
      const newCampaign = sortedCampaigns?.find(campaign => campaign?.id === campaignId)
      newVehicle = {
        ...newVehicle,
        campaignId,
        campaignImages: newCampaign?.images,
        campaignTexts: newCampaign?.texts,
      }
    }
  })
  return newVehicle
}

export const getSellAvailability = (availability: number) => {
  switch (availability) {
    case 0:
      return 'available'
    case 1:
      return 'comingsoon'
    case 2:
      return 'soldout'
    default:
      return 'unknownavailability'
  }
}

export const getAvailability = (availability: number) => {
  switch (availability) {
    case 0:
      return 'readyfordelivery'
    case 1:
      return 'new'
    case 2:
      return 'available'
    case 3:
      return 'comingsoon'
    case 4:
      return 'soldout'
    default:
      return 'unknownavailability'
  }
}

export const getPrimaryImage = (vehicle: any) => {
  if (vehicle?.mainImage && vehicle?.images) {
    return vehicle.images.find((image: any) => image.id === vehicle.mainImage)?.url
  }
  if (vehicle?.selectedImageId && vehicle?.images) {
    return vehicle.images.find((image: any) => image.id === vehicle.selectedImageId)?.url
  }
  if (vehicle?.images?.length > 1) {
    return vehicle.images.find((image: any) => image.order === 0)?.url
  }
  return vehicle?.images?.[0]?.url
}

export const roundDownInteger = (number: number, multiplyer = 100) => {
  return Math.floor(number / multiplyer) * multiplyer
}

export const isValidUrl = (url: string) => {
  try {
    const newUrl = new URL(url)
    return newUrl.protocol === 'http:' || newUrl.protocol === 'https:'
  } catch (e) {
    return false
  }
}

export const getEnabledPricing = (pricing: any[]) => {
  return pricing.filter(price => price.enabled)
}
